import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

import Utils from '../services/utils'

export default class BannerComponent {
    constructor() {
        this.bannerSlider()
    }

    async bannerSlider() {
        const options = {
            loop: true,
            autoplay: {
                delay: 3000,
            },
            speed: 2000,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            zoom: {
                maxRatio: 1.05,
                minRatio: 1,
            },
            lazy: {
                loadPrevNext: true,
            },
            grabCursor: true,

            // Navigation arrows
            navigation: {
                nextEl: '#swiper-banner-next',
                prevEl: '#swiper-banner-prev',
            },
        }

        Utils.swiperWrapper('#header-banner-slider', 'div')

        await Swiper.create('#header-banner-slider', options)
    }
}
