/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import BannerComponent from './components/banner.component'
import NavbarComponent from './components/navbar.component'
import ToolsComponent from './components/tools.component'

document.addEventListener('DOMContentLoaded', () => {
    //Component
    new BannerComponent()
    new NavbarComponent()
    new ToolsComponent()

    //Front page only
    if ($('.home').length > 0) {
        import(
            '@scripts/services/home.service' /* webpackChunkName: "scripts/home.service" */
        ).then(({ default: HomeService }) => {
            new HomeService()
        })
    }

    //Fullscreen template only
    if ($('.fullscreen').length > 0) {
        import(
            '@scripts/services/layout.service' /* webpackChunkName: "scripts/layout.service" */
        ).then(({ default: LayoutService }) => {
            new LayoutService()
        })
    }
})
