export default class ToolsComponent {
    constructor() {
        ToolsComponent.langSwitcher()
    }

    static langSwitcher() {
        const current_lang = $('.tools__item__languages__current')
        const list_langs = $('.tools__item__languages__langs')

        //Open submenu
        $(current_lang).on('click', () => {
            $(list_langs).toggleClass('tools__item__languages__langs--open')
        })

        //Select lang
        $(list_langs).on('click', () => {
            $(list_langs).toggleClass('tools__item__languages__langs--open')
        })

        // Fix current lang
        $(window).on('load', () => {
            setTimeout(() => {
                const current_language = $('html').attr('lang').substr(0, 2).toUpperCase()

                $('.tools__item__languages__current span').text(current_language)
            }, 1000)
        })
    }
}
